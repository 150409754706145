export const gallery = [
  {
    year: 2024,
    photos: [
      'photo-1.jpg',
      'photo-2.jpg',
      'photo-3.jpg',
      'photo-4.jpg',
      'photo-5.jpg',
      'photo-6.jpg',
      'photo-7.jpg',
      'photo-8.jpg',
      'photo-9.jpg',
      'photo-10.jpg',
      'photo-11.jpg',
      'photo-12.jpg',
      'photo-13.jpg',
      'photo-14.jpg',
      'photo-15.jpg',
      'photo-16.jpg',
      'photo-17.jpg',
      'photo-18.jpg',
      'photo-19.jpg',
      'photo-20.jpg',
      'photo-21.jpg',
      'photo-22.jpg',
      'photo-23.jpg',
      'photo-24.jpg',
      'photo-25.jpg',
      'photo-26.jpg',
      'photo-27.jpg',
      'photo-28.jpg',
      'photo-29.jpg',
      'photo-30.jpg',
      'photo-31.jpg',
      'photo-32.jpg',
      'photo-33.jpg',
      'photo-34.jpg',
      'photo-35.jpg',
      'photo-36.jpg',
      'photo-37.jpg',
      'photo-38.jpg',
      'photo-39.jpg',
      'photo-40.jpg',
      'photo-41.jpg',
      'photo-42.jpg',
      'photo-43.jpg',
      'photo-44.jpg',
      'photo-45.jpg',
      'photo-46.jpg',
      'photo-47.jpg',
      'photo-48.jpg',
      'photo-49.jpg',
      'photo-50.jpg',
      'photo-51.jpg',
      'photo-52.jpg',
      'photo-53.jpg',
      'photo-54.jpg',
      'photo-55.jpg',
      'photo-56.jpg',
      'photo-57.jpg',
      'photo-58.jpg',
      'photo-59.jpg',
      'photo-60.jpg',
      'photo-61.jpg',
      'photo-62.jpg',
      'photo-63.jpg',
      'photo-64.jpg',
      'photo-65.jpg',
      'photo-66.jpg',
      'photo-67.jpg',
      'photo-68.jpg',
      'photo-69.jpg',
      'photo-70.jpg',
      'photo-71.jpg',
      'photo-72.jpg',
      'photo-73.jpg',
      'photo-74.jpg',
      'photo-75.jpg',
      'photo-76.jpg',
      'photo-77.jpg',
      'photo-78.jpg',
      'photo-79.jpg',
      'photo-80.jpg',
      'photo-81.jpg',
      'photo-82.jpg',
      'photo-83.jpg',
      'photo-84.jpg',
      'photo-85.jpg',
      'photo-86.jpg',
      'photo-87.jpg',
      'photo-88.jpg',
      'photo-89.jpg',
      'photo-90.jpg',
      'photo-91.jpg',
      'photo-92.jpg',
      'photo-93.jpg',
      'photo-94.jpg',
      'photo-95.jpg',
      'photo-96.jpg',
      'photo-97.jpg',
      'photo-98.jpg',
      'photo-99.jpg',
      'photo-100.jpg',
      'photo-101.jpg',
      'photo-102.jpg',
      'photo-103.jpg',
      'photo-104.jpg',
      'photo-105.jpg',
      'photo-106.jpg',
      'photo-107.jpg',
      'photo-108.jpg',
      'photo-109.jpg',
      'photo-110.jpg',
      'photo-111.jpg',
      'photo-112.jpg',
      'photo-113.jpg',
      'photo-114.jpg',
      'photo-115.jpg',
      'photo-116.jpg',
      'photo-117.jpg',
      'photo-118.jpg',
      'photo-119.jpg',
      'photo-120.jpg',
      'photo-121.jpg',
      'photo-122.jpg',
      'photo-123.jpg',
      'photo-124.jpg',
      'photo-125.jpg',
      'photo-126.jpg',
      'photo-127.jpg',
      'photo-128.jpg',
      'photo-129.jpg',
      'photo-130.jpg',
      'photo-131.jpg',
      'photo-132.jpg',
      'photo-133.jpg',
      'photo-134.jpg',
      'photo-135.jpg',
      'photo-136.jpg',
      'photo-137.jpg',
      'photo-138.jpg',
      'photo-139.jpg',
      'photo-140.jpg',
      'photo-141.jpg',
      'photo-142.jpg'
    ],
  }
]