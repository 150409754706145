import React from "react"
import Contacts from "../components/Contact/Contacts"
import Hero from "../components/Hero/Hero"
import About from "../components/About"
import Sponsors from "../components/Sponsors/Sponsors"
import Venue from "../components/Venue/Venue"
import Sponsorship, { packages } from "../components/Sponsorship"
import Quickstats from "../components/Quickstats/Quickstats"
import CategoryCards from "../components/CategoryCards"
import { data } from "../components/Category"
import { sponsors } from "../components/Sponsors/SponsorsData"
import Panels from "../views/Panels"
import Agenda from "../components/Agenda"
import Tables from "../components/Tables"
import { Helmet } from 'react-helmet-async';
import Video from "../components/Video";

function Home() {
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://www.cnawards.com/",
            "name": "Comms National Awards",
            "description": "Comms Dealer was the first to recognise excellence in the channel and the Comms National Awards remain the channel's most respected and coveted prize.",
            "publisher": {
              "@type": "Organization",
              "name": "Comms Dealer"
            }
          }
          `}
        </script>
      </Helmet>
      <div>
        <Hero />
        <About />
        <Quickstats />
        <Video/>
        {/* <Tables /> */}
        <CategoryCards title={'Categories'} topics={data} />
        <Sponsors sponsors={sponsors} />
        {/* <Sponsorship packages={packages} /> */}
        <Venue title={"Celebrating the UK ICT experts at one of London's leading venues"} />
        <Agenda />
        <Panels />
        <Contacts />
      </div>
    </>
  );
}

export default Home;
